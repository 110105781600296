/* eslint-disable prefer-object-spread */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'Utils/Toast';
import addressService from './addressService';

// Step 3: Define an initial state
const initialState = {
  defaultAddress: [],
  otherAddresses: [],
  billingAddress: [],
  status: 'idle',
  error: null,
};

export const fetchAllAddress = createAsyncThunk(
  'addresses/fetchAllAddress',
  async (_, thunkAPI) => {
    try {
      return await addressService.AllAddress();
    } catch ({ response, message }) {
      const errorMessage = response?.data?.message || message;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  },
);

export const addAddress = createAsyncThunk('addresses/addAddress', async (data, thunkAPI) => {
  try {
    return await addressService.AddAddress(data);
  } catch ({ response, message }) {
    const errorMessage = response?.data?.message || message;
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const deleteAddress = createAsyncThunk('addresses/deleteAddress', async (id, thunkAPI) => {
  try {
    return await addressService.DeleteAddress(id);
  } catch ({ response, message }) {
    const errorMessage = response?.data?.message || message;
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const updateAddress = createAsyncThunk('addresses/updateAddress', async (data, thunkAPI) => {
  try {
    return await addressService.UpdateAddress(data);
  } catch ({ response, message }) {
    const errorMessage = response?.data?.message || message;
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    resetAddress: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        const { defaultAddress, otherAddresses } = payload;
        state.defaultAddress = [...defaultAddress];
        state.otherAddresses = [...otherAddresses];
      })
      .addCase(fetchAllAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        const { createCustomerAddress } = payload;
        if (createCustomerAddress.default_shipping) {
          if (state.defaultAddress?.length >= 1) {
            const updatedDefaultAddress = {
              ...state.defaultAddress[0],
              default_shipping: false,
              default_billing: false,
            };
            state.otherAddresses.push(updatedDefaultAddress);
          }
          state.defaultAddress = [createCustomerAddress];
        } else {
          state.otherAddresses.push(createCustomerAddress);
        }
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        notification.error(action.error.message);
      })
      .addCase(deleteAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        notification.success('Address Successfully Deleted');

        if (payload.status) {
          state.otherAddresses = state.otherAddresses?.filter((item) => item.id !== payload.id);
        }
      })
      .addCase(updateAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAddress.fulfilled, (state, { payload }) => {
        const { updateCustomerAddress } = payload;

        if (!updateCustomerAddress?.default_shipping) {
          const isDefaultAddress = state.defaultAddress[0]?.id === updateCustomerAddress.id;

          state.defaultAddress = isDefaultAddress ? [] : state.defaultAddress;
          state.otherAddresses = isDefaultAddress
            ? [...state.otherAddresses, updateCustomerAddress]
            : state.otherAddresses.map((obj) =>
                obj.id === updateCustomerAddress.id ? { ...obj, ...updateCustomerAddress } : obj,
              );
        } else {
          let updatedDefaultAddress;
          if (state.defaultAddress.length >= 1) {
            updatedDefaultAddress = Object.assign({}, state.defaultAddress[0], {
              default_shipping: false,
              default_billing: false,
            });
          }

          state.defaultAddress = [updateCustomerAddress];
          state.otherAddresses =
            updatedDefaultAddress === undefined ||
            updateCustomerAddress.id === updatedDefaultAddress?.id
              ? [...state.otherAddresses.filter((item) => item.id !== updateCustomerAddress?.id)]
              : [
                  updatedDefaultAddress,
                  ...state.otherAddresses.filter((item) => item.id !== updateCustomerAddress?.id),
                ];
        }
        state.status = 'succeeded';
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetAddress } = addressSlice.actions;

export const getAddressStatus = (state) => state.addresses.status;
export const getAddressError = (state) => state.addresses.error;
export const getDefaultAddress = (state) => state.addresses.defaultAddress;
export const getOtherAddresses = (state) => state.addresses.otherAddresses;
export const getDefaultAddressId = (state) => state.addresses.defaultAddress[0]?.id;
export const getDefaultAddressPostCode = (state) => state.addresses.defaultAddress[0]?.postcode;

export default addressSlice.reducer;
