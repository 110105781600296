import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openLoginModal } from 'Features/login';

const ProtectedRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem('token')) dispatch(openLoginModal(true));
  }, []);

  if (localStorage.getItem('token')) return <Outlet />;

  const b2bLogout = localStorage.getItem('b2b-log-out');

  if (b2bLogout) {
    localStorage.removeItem('isB2B');

    localStorage.removeItem('b2b-log-out');
    window.location.href = '/business';
  } else {
    return <Navigate to='/' />;
  }
};

const ProtectedB2BRoute = ({ isAllowed, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAllowed) {
      navigate('/');
    }
  }, [isAllowed, navigate]);

  if (!isAllowed) return null;

  return children;
};

export default ProtectedRouter;
export { ProtectedB2BRoute };
