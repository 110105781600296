import somethingWentWrong from 'assets/images/something_went_wrong.png';
import invalidUrl from 'assets/images/invalidUrl.png';
import { useEffect } from 'react';
import chunkFileVersioningHandler from 'Utils/chunkFileVersioningHandler';
import './ErrorBoundry.css';

const FallbackPage = (props) => {
  useEffect(() => {
    chunkFileVersioningHandler(props);
  }, []);

  function handleClick() {
    window.location.href = '/';
  }

  return props.error.message === 'invalid page url' ? (
    <div className='errorBoundry invalidUrl'>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <h3>Requested URL is not available</h3>
        <p>The Item you are searching for is unavailable at the moment</p>
        <button className='whiteCta' type='button' onClick={handleClick}>
          GO TO HOME
        </button>
      </div>
      <img src={invalidUrl} className='img-fluid' alt='Invalid Url' />
    </div>
  ) : (
    <div className='errorBoundry'>
      <img src={somethingWentWrong} className='img-fluid errorImg' alt='Something Went Wrong' />
      <h2>Something went wrong</h2>
      <div className='errorButton d-flex gap-3'>
        <button
          type='button'
          className='tryButton whiteCta'
          onClick={() => window.location.reload()}
        >
          TRY AGAIN
        </button>
        <button type='button' className='tryButton blackCta' onClick={handleClick}>
          RETURN TO HOME PAGE
        </button>
      </div>
    </div>
  );
};

export default FallbackPage;
