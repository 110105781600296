import React from 'react';
import { useSelector } from 'react-redux';
import { getGlobalLoading } from 'Features/GlobalLoading/LoadingSlice';
import '../../Styles/globalLoading.css';
import ThreeDotsLoading from './ThreeDotsLoading';

const GlobalLoading = () => {
  const isLoading = useSelector((state) => getGlobalLoading(state));

  return (
    isLoading && (
      <div className='loadingBackdrop'>
        <ThreeDotsLoading />
      </div>
    )
  );
};

export default GlobalLoading;
