import React, { lazy } from 'react';
import propTypes from 'prop-types';

import {
  B2B_FAQ,
  BANNER,
  BLOG_SLIDER,
  BRAND_CIRCLE_CAROUSEL,
  BRAND_LOGO,
  BRAND_SLIDER,
  BUY_AGAIN,
  CAROUSEL,
  CATEGORY_CIRCLE,
  CAT_TILES,
  FAQ_SLIDER,
  GRID,
  GRID_IMG_DESC,
  ICON_SLIDER,
  IMAGE_SLIDER,
  IMG_TILES,
  IMG_TOP_DESC,
  IMG_WITH_DESC,
  LOOK_SLIDER,
  OFFER_SLIDER,
  PROD_SLIDER,
  SEC_CAROUSEL,
  SLIDER,
  SLIDER_TITLE_DESC,
  STATIC_BANNER,
  STEP_SLIDER,
  SWIRL_SLIDER,
  VIDEO_BANNER,
  VIDEO_PLAYER,
  VIDEO_SLIDER,
  VIRTUAL_PROD_SLIDER,
  STRIPE_SLIDER,
  CONTENT_SLIDER,
  RECENT_VIEW,
} from 'Utils/Strings';

import 'Components/Homepage/landingPage.css';
import LazyOnScroll from 'Components/Global/LazyOnScroll';

const OfferBanner = lazy(() => import('Components/Offers/OfferBanner'));
const OfferSlider = lazy(() => import('Components/Offers/OfferSlider'));
const OfferSection = lazy(() => import('Components/Offers/OfferSection'));
const BrandStoreGridImage = lazy(() => import('Components/BrandStore/BrandStoreGridImage'));
const BrandStoreDescription = lazy(() => import('Components/BrandStore/BrandStoreDescription'));
const SeasonalVideos = lazy(() => import('Components/Community/SeasonalVideos'));
const SeasonalBanner = lazy(() => import('Components/Community/SeasonalBanner'));
const ImagetopDesc = lazy(() => import('Components/CommunityDetails/ImagetopDesc'));
const ImagewithDesc = lazy(() => import('Components/CommunityDetails/ImagewithDesc'));
const Videoplayer = lazy(() => import('Components/CommunityDetails/CommunityVideoplayer'));
const BrandFeature = lazy(() => import('Components/BrandLanding/BrandFeature'));
const BrandCircleCarousel = lazy(() => import('Components/BrandLanding/BrandCircleCarousel'));
const Blogs = lazy(() => import('./Blogs'));
const Exclusives = lazy(() => import('./Exclusives'));
const HomeOffers = lazy(() => import('./HomeOffers'));
const LandingCarousel = lazy(() => import('./LandingCarousel'));
const SecondaryCarousel = lazy(() => import('./SecondaryCarousel'));
const LooksSection = lazy(() => import('./LooksSection'));
const SliderWithTabs = lazy(() => import('./SliderWithTabs'));
const VideoSlider = lazy(() => import('./VideoSlider'));
const SwirlSlider = lazy(() => import('./SwirlSlider'));
const FaqSlider = lazy(() => import('./FaqSlider'));
const BuyAgain = lazy(() => import('B2B/Components/Homepage/BuyAgain'));
const StripeBanner = lazy(() => import('Components/Global/StripeSlider/StripeBanner'));
const ImageSlider = lazy(() => import('B2B/Components/Homepage/ImageSlider'));
const IconSlider = lazy(() => import('B2B/Components/Homepage/IconSlider'));
const StepSlider = lazy(() => import('B2B/Components/Homepage/StepSlider'));
const BrandLogo = lazy(() => import('B2B/Components/Homepage/BrandLogo'));
const StaticBanner = lazy(() => import('B2B/Components/Homepage/StaticBanner'));
const B2BFaqSlider = lazy(() => import('B2B/Components/Homepage/B2BFaqSlider'));
const ContentSlider = lazy(() => import('B2B/Components/Homepage/ContentSlider'));
const RecentView = lazy(() => import('./RecentVieweProducts'));

const componentMap = {
  [CAROUSEL]: LandingCarousel,
  [SLIDER]: HomeOffers,
  [GRID]: Exclusives,
  [SEC_CAROUSEL]: SecondaryCarousel,
  [PROD_SLIDER]: SliderWithTabs,
  [LOOK_SLIDER]: LooksSection,
  [BLOG_SLIDER]: Blogs,
  [VIDEO_SLIDER]: VideoSlider,
  [IMG_TILES]: OfferSection,
  [VIDEO_BANNER]: SeasonalVideos,
  [SLIDER_TITLE_DESC]: SeasonalBanner,
  [OFFER_SLIDER]: OfferSlider,
  [BANNER]: OfferBanner,
  [CAT_TILES]: BrandStoreGridImage,
  [GRID_IMG_DESC]: BrandStoreDescription,
  [IMG_TOP_DESC]: ImagetopDesc,
  [IMG_WITH_DESC]: ImagewithDesc,
  [VIDEO_PLAYER]: Videoplayer,
  [CATEGORY_CIRCLE]: BrandFeature,
  [SWIRL_SLIDER]: SwirlSlider,
  [FAQ_SLIDER]: FaqSlider,
  [VIRTUAL_PROD_SLIDER]: SliderWithTabs,
  [BRAND_SLIDER]: ImagetopDesc,
  [BRAND_CIRCLE_CAROUSEL]: BrandCircleCarousel,
  [BUY_AGAIN]: BuyAgain,
  [STRIPE_SLIDER]: StripeBanner,
  [IMAGE_SLIDER]: ImageSlider,
  [ICON_SLIDER]: IconSlider,
  [STEP_SLIDER]: StepSlider,
  [BRAND_LOGO]: BrandLogo,
  [STATIC_BANNER]: StaticBanner,
  [B2B_FAQ]: B2BFaqSlider,
  [CONTENT_SLIDER]: ContentSlider,
  [RECENT_VIEW]: RecentView,
};

/**
 *
 * @param root0
 * @param root0.banner
 * @param root0.isDesktop
 */
function SliderWrapper({ banner, isDesktop, arr, setQuery }) {
  const renderComponent = () => {
    const Component = componentMap[banner?.slider_type];
    if (Component) {
      return (
        <LazyOnScroll threshold={0.25} className='lazy-load-container'>
          <Component
            banner={banner}
            isDesktop={isDesktop}
            {...(arr ? { arr } : {})}
            {...(setQuery ? { setQuery } : {})}
          />
        </LazyOnScroll>
      );
    }

    return null;
  };

  return renderComponent();
}

SliderWrapper.propTypes = {
  banner: propTypes.object.isRequired,
  isDesktop: propTypes.bool,
};

export default SliderWrapper;
