/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { DeviceMobileCamera, Handbag, HeartStraight, List, SignOut, User } from 'phosphor-react';
import { connect, useDispatch, useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { ListGroup } from 'react-bootstrap';
import { CATEGORIES, GET_BRANDS_LIST } from 'B2B/Gql';
import close from 'assets/icons/Header/close.svg';
import gb_main from 'assets/images/GBook_Logo-B2C.svg';
import gb_business_logo from 'B2B/Assets/Icons/GB-Biz-Logo-png.png';
import { changeB2BTrigger, changeTrigger } from 'Features/login';
import { resetAllOnLogout } from 'Features/global/globalAction';
import './header.css';
import './SlideMenu.css';
import './SideMenu.css';
import Popup from 'Components/Login popup/Popup';
import Form from 'Components/Login popup/Form';
import { notification } from 'Utils/Toast';
import { IsB2BLogged, IsB2BRoute } from 'B2B/Utils/IsB2B';
import B2BForm from 'B2B/Components/SignInSignUp/Form/Form';
import { pushToDataLayer } from 'Utils/pushToDataLayer';
import { BANNER_SLIDER } from 'Gql/LandingPages';
import { SCHEDULER } from 'Utils/Strings';
import MultilevelSideNav from './MultiLevelSideNav';
import MobileSearch from './MobileSearch';
import InnerHtml from './InnerHtml';

/**
 *
 * @param root0
 * @param root0.cartID
 */
function MobileHeader({ wishlistCount, cartCount, userFirstName, userMobileNumber }) {
  const [loadBrandsList, { data: brandData }] = useLazyQuery(GET_BRANDS_LIST, {});

  const [sideNavState, setSideNavState] = useState(false);
  const { data: stripData, loading: stripLoading } = useQuery(BANNER_SLIDER, {
    variables: {
      page_type: 'global',
      type: 'web',
    },
  });
  const { data, loading, error } = useQuery(CATEGORIES, {
    fetchPolicy: 'no-cache',
  });
  const [mobileNavData, setMobileData] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isHideTopNavigation = ['/shopping-cart', '/payment', '/checkout', '/voucherdetails'].some(
    (path) => pathname.includes(path),
  );

  const storedObjString = JSON.parse(localStorage.getItem('sortBy'));

  if (pathname !== storedObjString?.pathname) {
    localStorage.removeItem('sortBy');
  }

  const loginTrigger = useSelector((state) => state.login.b2bTrigger);

  const [, setIsCheckoutAddressPage] = useState(pathname.includes('/checkout/address'));

  const profileCompletion = useSelector((state) => state.cart?.profileCompletion);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (profileCompletion < 100) setIncomplete(true);
      else setIncomplete(false);
    }
  }, [pathname, profileCompletion]);

  useEffect(() => {
    const isCheckout = pathname.includes('/checkout/address');
    setIsCheckoutAddressPage(isCheckout);
  }, [pathname]);

  const handleScroll = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [showPopover]);

  const signOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('zipcode');
    if (IsB2BLogged()) {
      localStorage.setItem('b2b-log-out', true);
      dispatch(resetAllOnLogout('b2b'));
      dispatchEvent(new Event('storage'));
      notification.success('Logout successfull!');
      return;
    }
    dispatch(resetAllOnLogout());
    window.dataLayer.push({ ecommerce: null });
    pushToDataLayer({
      event: 'SignOut',
      ecommerce: {
        mobile: userMobileNumber,
      },
    });
    dispatchEvent(new Event('storage'));
    notification.success('Logout successfull!');
  };

  useEffect(() => {
    const newdata = [];
    const branddata = [
      {
        name: 'BRANDS',
        children: [
          {
            name: 'Just Launched',
            children: [],
          },
          {
            name: 'Feature Brands',
            children: [],
          },
          {
            name: 'Exclusive',
            children: [],
          },
          {
            name: 'Popular Brands',
            children: [],
          },
        ],
      },
    ];

    if (brandData && brandData.getBrandsList) {
      brandData?.getBrandsList.forEach((item) => {
        if (parseInt(item.is_justin)) {
          branddata[0].children[0].children.push({
            name: item.title,
            url_path: item.url_key,
          });
        }
        if (parseInt(item.is_featured)) {
          branddata[0].children[1].children.push({
            name: item.title,
            url_path: item.url_key,
          });
        }
        if (parseInt(item.is_exclusive)) {
          branddata[0].children[2].children.push({
            name: item.title,
            url_path: item.url_key,
          });
        }
        if (parseInt(item.is_popular)) {
          branddata[0].children[3].children.push({
            name: item.title,
            url_path: item.url_key,
          });
        }
      });
    }

    if (data && data.categories && data?.categories?.items) {
      data.categories.items[0]?.children?.forEach((cats) => {
        if (cats?.include_in_menu === 1) {
          newdata.push(cats);
        }
      });
    }

    setMobileData(() => [...newdata, ...branddata]);
  }, [data, brandData]);

  const [isOpen, setIsopen] = useState(false);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      if (isOpen) {
        setTimeout(() => {
          mainContent.style.display = 'none';
        }, 600);
      } else {
        mainContent.style.display = 'block';
      }
    }
  }, [isOpen]);

  const ToggleSidebar = () => {
    setIsopen(!isOpen);
    if (!isOpen) {
      loadBrandsList();
    }
  };

  const popover = (
    <Popover id='popover-basic' className='hed m-nvbr'>
      <Popover.Body>
        <ListGroup as='ul' className='pop-head'>
          {userFirstName && (
            <ListGroup as='ul' className='signout pop-signin-li'>
              <ListGroup.Item as='li' className='text-left txtbld border-0'>
                Hello,
                <span className='pop-user-name'>
                  {userFirstName.length > 5 ? `${userFirstName.substring(0, 5)}...` : userFirstName}
                </span>
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                role='button'
                className='signout-li whiteCta'
                onClick={() => {
                  navigate('/profile');
                  setShowPopover(false);
                }}
                action
              >
                <User size={18} weight='thin' />
                <span className='signout-cont'>My Account</span>
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                role='button'
                className='signout-li whiteCta'
                onClick={() => {
                  navigate('/profile/order');
                  setShowPopover(false);
                }}
                action
              >
                <Handbag size={18} weight='thin' />
                <span className='signout-cont'>My Orders</span>
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                role='button'
                className='signout-li whiteCta'
                onClick={signOut}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <SignOut size={18} weight='thin' />
                <span className='signout-cont'>Sign out</span>
              </ListGroup.Item>
            </ListGroup>
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      className={`m-header sticky-top ${isHideTopNavigation ? 'cart-m-header' : ''} ${
        !(IsB2BRoute() && !IsB2BLogged()) ? 'm-header' : 'm-header-margin'
      }`}
    >
      {loginTrigger && <div className='overlay b2b-landing-overlay' />}

      <div className='m-header-main '>
        {(!isHideTopNavigation || !stripLoading) && (
          <div className='exclusive d-flex justify-content-between align-items-center'>
            <InnerHtml
              element='div'
              className={
                stripData?.BannerSlider.find((elm) => elm.slider_type === SCHEDULER)?.is_animate ===
                  1 && 'exclusiveInner'
              }
              html={
                stripData?.BannerSlider.find((elm) => elm.slider_type === SCHEDULER)?.banners[0]
                  .additional_information
              }
            />

            <div className='get-app-logo d-flex justify-content-end'>
              {!IsB2BLogged() && IsB2BRoute() ? (
                <a href='/' className='getAppCta underline'>
                  GlamourBook.com
                </a>
              ) : (
                !IsB2BLogged() && (
                  <Link to='/get-app' className='getAppCta'>
                    <DeviceMobileCamera size={10} color='#FFFFFF' />
                    GET APP
                  </Link>
                )
              )}
            </div>
          </div>
        )}

        <div className='m-header-content m-header-row d-flex justify-content-between'>
          {!isHideTopNavigation && (
            <div className='m-header-left' id='burger'>
              <div role='button' tabIndex={0} className='menu' onClick={ToggleSidebar}>
                <List size={32} />
              </div>

              {!(IsB2BRoute() && !IsB2BLogged()) && (
                <nav className='navbar navbar-expand-sm m-nvbr'>
                  <div className=' navbar-collapse'>
                    <ul className='m-navbar-nav'>
                      <li
                        className='nav-item dropdown h-icon dropdown-toggle'
                        role='button'
                        onClick={() => {
                          if (!userFirstName) {
                            dispatch(changeTrigger());
                          }
                          setShowPopover(!showPopover);
                        }}
                      >
                        {userFirstName ? (
                          <OverlayTrigger
                            trigger='click'
                            rootClose
                            placement='bottom'
                            overlay={popover}
                            className='pophead'
                            show={showPopover}
                            onToggle={setShowPopover}
                          >
                            <span className='m-headicon'>
                              <div
                                className={`${
                                  incomplete && userFirstName ? 'mobile-icon-wrapper' : ''
                                }`}
                              >
                                <User size={24} weight='thin' color='#000' />
                              </div>
                              <span className='pop-user-name usname'>
                                {userFirstName?.length > 7
                                  ? `${userFirstName.substring(0, 7)}...`
                                  : userFirstName}
                              </span>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span className='m-headicon'>
                            <User size={24} weight='thin' color='#000' />
                            <span className='pop-user-name usname'>Login</span>
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
              )}

              <div className={`sidebar ${isOpen === true ? 'active' : ''}`}>
                <div className='menu-logo-sec'>
                  <div className='action m-nav-toggle'>
                    <div role='button' tabIndex={0} className='btn-nav' onClick={ToggleSidebar}>
                      <img src={close} alt='' className='m-side-close' />
                    </div>
                  </div>
                </div>

                {!loading ? (
                  !error ? (
                    <MultilevelSideNav
                      ToggleSidebar={ToggleSidebar}
                      sideNavState={sideNavState}
                      sideNavHandler={setSideNavState}
                      data={mobileNavData}
                      monetizeData={brandData?.getBrandsList}
                    />
                  ) : (
                    'failed to fetch'
                  )
                ) : null}
              </div>
              <div
                role='button'
                tabIndex={0}
                className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`}
                onClick={ToggleSidebar}
              />
            </div>
          )}

          <div className='m-header-center'>
            <div className='m-brand'>
              <a href={!(IsB2BRoute() && !IsB2BLogged()) && '/'}>
                <img
                  src={IsB2BRoute() ? gb_business_logo : gb_main}
                  alt='GlamourBook'
                  className={IsB2BRoute() ? 'b2b_brandLogo' : 'brandLogo'}
                />
              </a>
            </div>
          </div>

          {!isHideTopNavigation && !(IsB2BRoute() && !IsB2BLogged()) && (
            <div className='m-header-right'>
              <nav className='navbar navbar-expand-sm'>
                <ul className='navbar-nav'>
                  <li className='m-h-icon'>
                    <Link
                      to='/wishlist'
                      onClick={() => {
                        pushToDataLayer({
                          event: 'Add_To_Wishlist',
                          buttonName: 'addtowishlist',
                        });
                      }}
                    >
                      {wishlistCount !== 0 ? (
                        <span className='m-shop-icon-chip-h'>{wishlistCount}</span>
                      ) : null}
                      <HeartStraight size={24} weight='thin' color='#000' />
                    </Link>
                  </li>
                  <li className='m-h-icon'>
                    <Link to='/shopping-cart'>
                      {cartCount !== 0 ? (
                        <span className='m-shop-icon-chip-h'>{cartCount}</span>
                      ) : null}
                      <Handbag size={24} weight='thin' color='#000' />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          {IsB2BRoute() && !IsB2BLogged() && (
            <div className='m-header-right'>
              <button
                type='button'
                className='blackCta b2BLoginRegister'
                onClick={() => dispatch(changeB2BTrigger(true))}
              >
                LOGIN
              </button>
            </div>
          )}
        </div>

        {!isHideTopNavigation && <MobileSearch />}
      </div>
      <Popup>
        <Form />
      </Popup>
      <B2BForm />
    </div>
  );
}
const mapStateToProps = (state) => ({
  cartCount: state.cart.cartCount,
  wishlistCount: state.wishlist.wishlistCount,
  userFirstName: state.cart.userFirstName,
  userMobileNumber: state.cart.userMobileNumber,
});
export default connect(mapStateToProps)(MobileHeader);
